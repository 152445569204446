/* App.css*/
/* styles.css */
:root {
  /* Default fallback colors if CSS variables are not set */
  --color1: #000000;
  --color2: #ffd44a;
  --color1-rgba: rgba(0, 0, 0, 0.4);
  --color2-rgba: rgba(255, 212, 74, 0.5);
}

html, body {
  margin: 0;
  font-family: 'Helvetica', sans-serif;
  background-color: #f3f1f7; /* Subtle light background */
  color: var(--color1); /* Magenta for text */
  padding: 0;
  font-size: 12px;
  /*height: 100%;
  width: 100%; /* Full width */
}

.App {
  min-height: 100%;
  height: 100%;
  min-width: 100%; /* Full width */
  padding: 10px;
  align-items: center; /* Center items horizontally */
  text-align: center;
  margin: auto;
  /*background-color: #f4d5e5; /*  background for app container */
  background: linear-gradient(to left, var(--color1), #f3f1f7, var(--color1));
  border-radius: 15px;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1); /* Smooth shadow */
}

.header {
  position: absolute; /* Position the header absolutely */
  /*top: 40px; /* Adjust to your liking */
  left: 70px; /* Position in the top right corner */
  z-index: 10; /* Ensure it's on top of other elements */
}

.logo {
  height: auto; /*80px; /* Adjust the height as needed */
  width: 10rem; /* Maintain aspect ratio */
}

/*.header-bg {
  background: linear-gradient(to left, #c55890, #f3f1f7, #c55890);
  padding-bottom: 20px;
}*/

h1 {
  font-size: 2em;
  /*margin-top: 30px;*/
  margin-bottom: 8px;
  color: #000000;
}

h2 {
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #000000;
}

h3 {
  font-size: 1.1em;
  margin-bottom: 15px;
  color: #000000;
}

.controls {
  margin-bottom: 2px;
  display: flex;
  flex-direction: row; /* Stack the controls vertically */
  gap: 20px; /* Space between the controls */
  width: 100%;
  align-items: center;
  justify-content: center;
}

label {
  font-size: 1em;
  margin-right: 10px;
  color: #000000;
}

select {
  padding: 8px 15px;
  border: 2px solid var(--color2);
  border-radius: 8px;
  background-color: #f3f1f7;
  color: #000000;
  font-size: 1em;
}

.line-chart-container,
.calendar-container,
.average-occupancy-container,
.pie-chart-container,
.bar-chart-container,
.map-chart-container,
.radar-chart-container,
.geofence-chart-container,
.live-mode-container,
.floorplan-container{
  width: 100%; /* Allow full width */
  height: 100%; /* Allow full height */
  padding: 10px;
  background-color: #ffffff;/*#DEDEDE;*/
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--color1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  flex: 1; /* Make plots responsive */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.average-occupancy-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content horizontally */
}
.fall-detection-container{
  width: 80%; /* Allow full width */
  height: 70%; /* Allow full height */
  padding: 10px;
  background-color: #ffffff;/*#DEDEDE;*/
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--color1);
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.4);
  margin-top: 40px;
  margin-left: 100px;
  flex: 1; /* Make plots responsive */
  display: flex;
  flex-direction: column;
  align-items: center;
}

table {
  height: 80%;
  width: 100%;
  margin: 10px 0;
}

th, td {
  /*padding: px;*/
  text-align: center;
  border-bottom: 1px solid #ddd;
  background-color: var(--color2-rgba);
  font-size: 1em;
  color:#ffffff;
}

th {
  background-color: var(--color2);
  color: #ffffff;
}


button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: var(--color1-rgba); /* Medium magenta for buttons */
  color: white; /* White text for better contrast */
  cursor: pointer;
  margin: 5px;
  pointer-events: auto;
  font-size: 0.7em;
}

button:hover {
  background-color: var(--color1); /* magenta on hover */
}

.calendar-plot {
  padding: 20px;
  border: 2px solid var(--color1); /* Border matching the theme */
  border-radius: 8px;
  background-color: #f3f1f7; /* Light background for calendar */
}

.layout {
  background-color: transparent; /* Keep background transparent */
  justify-content: center;
  width: 100%;
}

.hamburger-icon { /* Hamburger icon */
  font-size: 2em;
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 85px;
  left: 20px;
  z-index: 30;
}

/* Sidebar when open */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15em;/*250px;*/
  height: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  transform: translateX(0); /* Initially open */
  transition: transform 0.3s ease-in-out;
}

/* Sidebar when closed */
.sidebar-closed {
  transform: translateX(-100%); /* Moves off the screen */
}

/* Content with sidebar */
.content-with-sidebar {
  margin-left: 15em; /* Push the content to the right */
  transition: margin-left 0.3s ease;
}

/* Content without sidebar */
.content {
  margin-left: 0;
}

/* Show sidebar */
.sidebar.open {
  transform: translateX(0); /* Slide in */
}

.button-container {
  display: flex;
  gap: 10px; /* Adjust spacing between buttons */
  justify-content: center; /* Center them horizontally */
  align-items: center; /* Align vertically */
}

/* Grid Layout */
.grid-layout {
  transition: margin-left 0.3s ease; /* Smooth transition when sidebar opens */
}

/* Adjust grid when the sidebar is open */
.grid-layout.with-sidebar {
  margin-left: 15em; /* Match the sidebar width */
}

.floorplan-container {
  /*display: flex;*/
  margin-top: 6em;
  transition: margin-left 0.3s ease;
}

.warning-message {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 0, 0, 0.8);
  color: white;
  padding: 15px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  z-index: 9999; /* Ensures it appears on top */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.error-icon {
  font-size: 36px;
  margin-right: 10px;
  vertical-align: middle;
}
